<template>
  <div id="cart" class="cart">
    <div class="contents" v-if="$store.state.cartItems.length > 0">
      <h4 class="text-center text-uppercase black">Shopping Cart</h4>
      <div class="cart-items">
        <div class="cart-item" v-for="(cartItem, index) in $store.state.cartItems" v-bind:key="cartItem['ID']">
          <div class="description">
            <h5 class="mb-0">{{ cartItem.post_title }}</h5>
            <div class="text-0 mb-1 text-capitalize" v-if="cartItem.post_type != null">{{ cartItem.post_type }}</div>
            <div class="text-0 mt-1" v-if="cartItem.notes != null && !cartItem.notes.includes('undefined')">{{ cartItem.notes }}</div>
            <div class="sub d-flex align-items-center">
              <input type="number" class="mr-1 text-center qty" min="1" v-model="cartItem.qty" style="width: 46px;" v-if="$store.state.orderStep == 'products' || $store.state.orderStep == 'cart'" />
              <span class="mr-2" v-else>
                <span class="font-weight-bold">QTY: </span>
                {{ cartItem.qty }}
              </span>
              <span class="price"><span style="text-decoration: line-through; display: inline-block; margin-right: .25rem;" v-if="cartItem.type+'Original' in cartItem.prices">${{ cartItem.prices[cartItem.type+'Original'] }}</span>${{ (cartItem.prices[cartItem.type]) ? cartItem.prices[cartItem.type] : '0.00' }}</span> / <span class="boxes">{{ getCartLabel(cartItem.type) }}</span>
            </div>
          </div>
          <a class="remove" @click="removeFromCart(index)" v-if="$store.state.orderStep == 'products' || $store.state.orderStep == 'cart'"><i class="far fa-times"></i></a>
        </div>
      </div>
    </div>
    <div class="contents" v-else>
      <h4 class="text-center text-uppercase black">Shopping Cart</h4>
      <div class="cart-item text-center d-flex justify-content-center">
        Your cart is empty.
      </div>
    </div>
    <div v-if="$store.state.userData != null && $store.state.userData.blocked != '_blank_'">
      <div class="text-center font-weight-bold red-links p-3 white-bg border text-1">You're unable to place orders at this time. Please contact our accounting department at <a href="mailto:accountsreceivable@oregonfruit.com">accountsreceivable@oregonfruit.com</a> or <a href="tel:5034859020">503-485-9020</a>.</div>
    </div>
    <div v-else>
      <a class="checkout button w-100" @click="$store.commit('changeOrderStep', 'cart')" v-if="$store.state.orderStep == 'products' && $store.state.cartItems.length > 0">View Cart and Checkout</a>
      <a class="checkout button w-100" @click="$store.commit('changeOrderStep', 'account')" v-if="$store.state.orderStep == 'cart'">Continue Checkout</a>
      <a class="checkout button w-100" @click="$store.commit('changeOrderStep', 'billing')" v-if="$store.state.orderStep == 'shipping' && $store.state.orderInfo.shippingMethod != null">Continue To Billing</a>
      <a class="checkout button w-100" @click="$store.commit('changeOrderStep', 'review')" v-if="$store.state.orderStep == 'billing' && $store.getters.isOrderComplete && financingAllowed">Review Order</a>
    <div>
    <div class="total-bar text-0" v-for="discount in $store.state.orderInfo.discounts" v-bind:key="discount.name">
      <label>
        <div class="text-capitalize">{{ discount.name }}</div>
        <div class="text-0 font-weight-normal" v-if="discount.sub != ''">{{ discount.sub }}</div>  
      </label>
      <div class="total" v-if="$store.getters.discountAmount(discount) != '0.00'">{{ formatDiscount(discount) }}<span v-if="['order', 'shipping'].includes(discount.target)"> (${{ $store.getters.discountAmount(discount) }})</span></div>
    </div>
    <div class="promo-bar pt-2" v-if="$store.state.orderInfo.discountAccepted != true && $store.state.orderStep != 'review'">
      <input type="text" v-model="discountCode" placeholder="Add Discount Code" />
    </div>
    <div class="total-bar mb-0 pb-0" @click="$store.commit('changeOrderStep', 'shipping')">
      <label>
        <div class="text-0 font-weight-bold">Shipping</div>
        <div class="text-0 font-weight-normal" v-if="$store.state.orderInfo.shippingMethod != null">{{ $store.state.orderInfo.shippingMethod.name }}</div>
        <div class="text-0 font-weight-normal" v-else>Shipping To Be Calculated</div> 
      </label>
      <div class="total text-0" v-if="$store.state.orderInfo.shippingMethod != null">${{ $store.getters.shippingPriceWithDiscounts }}</div>
      <div class="total text-0" v-else>$</div>
    </div>
    <div class="total-bar text-0 d-none" v-if="$store.getters.pallets > 0">
      <label>
        <div class="text-0">Pallet Fees</div>
        <div class="text-0 font-weight-normal">$20 / pallet</div>
      </label>
      <div class="total">${{ parseFloat($store.getters.pallets * 20).toFixed(2) }}</div>
    </div>
    <div class="total-bar text-0" v-if="$store.state.orderInfo.liftgate == true">
      <label>
        <div>Liftgate Fee</div> 
      </label>
      <div class="total">$45</div>
    </div>
    <div class="total-bar text-0" v-if="$store.state.orderInfo.appointmentFee == true">
      <label>
        <div>Appointment Fee</div> 
      </label>
      <div class="total">$15</div>
    </div>
    <div class="total-bar align-items-center border-bottom">
      <label class="text-1 text-uppercase">Product Price</label>
      <div class="total">${{ $store.getters.cartFinalTotal }}</div>
    </div>
    <div class="total-bar" title="The number of items that will fit in a pallet. You can use this calculation to control the number of pallets used for your order, which can greatly influence the cost of shipping.">
      <label>
        <div class="text-0 mb-1 font-weight-bold"># of Pallets</div>
        <div class="text-0 font-weight-normal">Boxes to Fill Pallet: {{ $store.getters.remainingPalletSpace.boxes }}</div>
        <div class="text-0 font-weight-normal">Drums to Fill Pallet: {{ $store.getters.remainingPalletSpace.drums }}</div>
        <div class="text-0 font-weight-normal">Totes to Fill Pallet: {{ $store.getters.remainingPalletSpace.totes }}</div>
        <div class="text-0 font-weight-normal pouches">Pouch Cases to Fill Pallet: {{ $store.getters.remainingPalletSpace.pouches }}</div>
        <div class="text-0 font-weight-normal pouches" v-if="$store.getters.typeTotals.lemonades != 0">Lemonade Cases to Fill Pallet: {{ $store.getters.remainingPalletSpace.lemonades }}</div>
      </label>
      <div class="total">{{ $store.getters.pallets }}</div>
    </div>
  </div>
</template>

<script>
import { validatePositiveQtys } from '@/helpers.js'
// import Api from '@/models/Api.js'

export default {
  name: 'Cart',
  data() {
    return {
      cart: [],
      paymentToken: null,
      discountCode: ''
    };
  },
  watch: {
    discountCode(newVal) {
      this.$store.commit('addDiscountCode', newVal);
    },
    '$store.state.cartItems': {
      handler(newCartItems) {
        let totalSamplesCount = newCartItems.reduce((count, item) => {
          return item.post_type === 'sample' ? count + parseInt(item.qty, 10) : count;
        }, 0);
        
        if (totalSamplesCount > 4) {
          this.$overlay(true, 'Sorry, you can only order 4 samples per order.');
          setTimeout(() => { this.$overlay(false); }, 1500);

          let excessSamples = totalSamplesCount - 4;

          // Reduce the quantity of sample items to ensure the total is 4 or less
          newCartItems.forEach(item => {
            if (item.post_type === 'sample' && excessSamples > 0) {
              let itemQty = parseInt(item.qty, 10);
              if (itemQty > excessSamples) {
                item.qty = itemQty - excessSamples;
                excessSamples = 0;
              }
              // } else {
              //   excessSamples -= itemQty;
              //   item.qty = 0;
              // }
            }
          });

          // Manually trigger reactivity to update the cart
          this.$forceUpdate();
        }

        newCartItems.forEach((item, index) => {
          console.log(item.qty);
          if (item.qty == 0) {
            this.removeFromCart(index);
            this.$forceUpdate();
          }
        });
      },
      deep: true
    }
  },
  computed: {
    cartItems() {
      return this.$store.state.cartItems;
    },
    financingAllowed() {
      if (this.$store.state.orderInfo.paymentMethod == 'Invoice' && this.$store.state.userData.financing != true) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    removeFromCart(index) {
      this.$store.commit('removeFromCart', index);
    },
    formatDiscount(discount) {
      if (discount.type == 'percentage') {
        return discount.discount + '%';
      }
    },
    getCartLabel(type) {
      if (type == 'pouch') {
        return 'pouch case';
      } else if (type == 'lemonade') {
        return 'case';
      } else {
        return type;
      }
    }
  },
  mounted() {
    validatePositiveQtys();
  }
}
</script>

<style lang="scss">

</style>